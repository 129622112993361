function StatusCard({ icon, title, value, additional, warning }) {
    return (
        <div className={`bg-white rounded-lg p-4 shadow-md border-l-4 border-${ warning ? 'red' : 'blue'}-500 flex items-center space-x-4 w-full`}>
            <div className="flex items-center">
                <div className={`text-${ warning ? 'red' : 'blue'}-500`}>
                    <i className={`${icon} fa-lg`}></i>
                </div>
                <div className="ml-4">
                    <div className="text-sm font-semibold text-gray-600">{title}</div>
                    <div className="text-lg font-bold">{value}</div>
                    {additional && <div className="text-gray-500 text-sm">{additional}</div>}
                </div>
            </div>
        </div>
    );
}

export default StatusCard;