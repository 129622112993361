
import { useEffect, useState } from 'react';
import StatusCard from './components/statusCard';
import './App.css';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const siteParam = urlParams.get('site');

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());
  const [siteInformation, setSiteInformation] = useState({
    state: "",
    mode: "",
    active: "",
    reactive: "",
    SoC: "",
    kWHOnline: "",
    unitsOnline: "",
    strsOnline: ""
  });

  const parseSiteInformation = (input) => {
    try {
      const data = JSON.parse(input);
      console.log(data)

      const customObject = {
        state: parseSiteState(data.body.SiteSt),
        mode: parseSiteMode(data.body.SiteMode),
        active: data.body.kW,
        reactive: data.body.kVAr,
        SoC: data.body.SoC,
        kWHOnline: data.body.kWHOnline,
        unitsOnline: data.body.UnitsOnline,
        strsOnline: data.body.StrsOnline,
        totalActivePowerCommand: data.body.kWout
      };

      console.log(customObject)
      setSiteInformation(customObject);
      return;
    } catch (error) {
      console.error("Error parsing input:", error);
      return null;
    }
  }

  const parseSiteMode = (input) => {
    var result = ""

    switch (input) {
      case 0:
        result = "Manual"
        break;
      case 1:
        result = "Auto"
        break;
    }

    return result;
  }

  const parseSiteState = (input) => {
    var result = ""

    switch (input) {
      case 0:
        result = "Ready"
        break;
      case 1:
        result = "Standby"
        break;
      case 2:
        result = "Running"
        break;
      case 3:
        result = "Fault"
        break;
    }

    return result;
  }

  const getSiteInformation = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("site", siteParam ?? "grand")

    var requestOptions = {
      method: 'GET',
      headers: myHeaders
    };

    var response = await fetch("https://indieenergy.azurewebsites.net/api/deviceStatus", requestOptions)
    var result = await response.text();
    return result;

  };

  useEffect(() => {
    const interval = setInterval(async () => {
      var workingDate = new Date;
      const currentTimeInSeconds = Math.floor(workingDate / 1000);
      if (currentTimeInSeconds % 30 === 0) {
        try {
          var siteInfo = await getSiteInformation();
          parseSiteInformation(siteInfo);
          setLastUpdated(workingDate);
        }
        catch (e) {
          console.error(e);
        }
      }
      setCurrentTime(workingDate);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <header className="bg-gray-800 text-white p-4 flex justify-between items-end">
        <div className="flex">
          <div className="mr-4">
            <h1 className="text-3xl font-bold">Site Status</h1>
          </div>
          <div className="mr-4">
            <div className="text-sm text-gray-500">
              Current Time: {currentTime.toLocaleTimeString()}
            </div>

            <div className="text-sm text-gray-500">
              Last Updated: {lastUpdated.toLocaleTimeString()}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-lg">Location:</span>
          <select className="rounded p-2 bg-gray-700 border border-gray-600 hover:border-gray-500 focus:outline-none">
            <option>{siteParam} St.</option>
          </select>
        </div>
      </header >
      <div className="flex flex-wrap gap-4 p-8 w-full overflow-auto">
        <StatusCard icon="fas fa-check-circle" title="Site Status" value={siteInformation.state + " " + siteInformation.mode} warning={false} />
        <StatusCard icon="fas fa-bolt" title="Active / Reactive Power" value={siteInformation.active + " kW / " + siteInformation.reactive + " kVar"} warning={false} />
        <StatusCard icon="fas fa-network-wired" title="Site SOC (Online)" value={siteInformation.SoC + " % / " + siteInformation.kWHOnline + " kWh"} additional={siteInformation.SoC + " % (Online and Offline)"} warning={false} />
        <StatusCard icon="fas fa-server" title="Units Status" value={siteInformation.unitsOnline + " Online"} warning={siteInformation.unitsOnline < 2} />
        <StatusCard icon="fas fa-solar-panel" title="# Strings" value={siteInformation.strsOnline} additional="Connected" warning={siteInformation.strsOnline < 64} />
        <StatusCard icon="fas fa-cogs" title="Total Active Power Command" value={siteInformation.totalActivePowerCommand + " kW"} warning={false} />
      </div>
    </div >
  );
}

export default App;
